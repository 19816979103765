/**
 * These are the available global classes as defined in the global.css file.
 */
export const AVAILABLE_SHADOWS = [
	'material-shadow--0dp',
	'material-shadow--1dp',
	'material-shadow--2dp',
	'material-shadow--3dp',
	'material-shadow--4dp',
	'material-shadow--6dp',
	'material-shadow--8dp',
	'material-shadow--9dp',
	'material-shadow--12dp',
	'material-shadow--16dp',
	'material-shadow--24dp',
] as const;
export const AVAILABLE_SHADOWS_DP = [0, 1, 2, 3, 4, 6, 8, 9, 12, 16, 24] as const;

export type Shadows = (typeof AVAILABLE_SHADOWS)[number];
export type ShadowsDP = 0 | 1 | 2 | 3 | 4 | 6 | 8 | 9 | 12 | 16 | 24;

/**
 * This function allows us to pass in a `dp` value and get the corresponding shadow class.
 *
 * We can reuse this in components where we need to dynamically set the shadow class without using the shadow component.
 */
export function getShadowClass(dp: ShadowsDP) {
	return `material-shadow--${dp}dp`;
}
